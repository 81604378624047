<template lang="pug">
  v-autocomplete(
    prepend-inner-icon="mdi-magnify"
    filled
    outlined
    :loading="arePartsKitsPending"
    :items="kits"
    placeholder="Add Parts Kit"
    hide-details
    hide-selected
    v-model="searchResult"
    dense
    return-object
    clearable
    auto-select-first
    @change="addParts($event)"
    :value="value"
    item-text="title"
    item-value="id"
    :rules="rules ? rules : null"
    :search-input.sync="searchString"
  )
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'

export default {
  name: 'PartsKitsAutocomplete',
  props: {
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String
    },
    shopId: {
      type: String,
      required: true
    },
    rows: {
      type: Array
    }
  },
  setup (props, context) {
    const searchString = ref('')
    const searchResult = ref(null)

    const { Kit } = context.root.$FeathersVuex.api

    const query = {
      $or: [
        {
          shop_id: props.shopId
        }
      ],
      $limit: 10
    }

    const kitsParams = computed(() => {
      const params = { ...query }

      if (searchString.value) {
        params.title = {
          $regex: searchString.value,
          $options: 'gi'
        }
      }

      return { query: params }
    })

    const kitsitsFetchParams = computed(() => {
      const params = { ...query }

      if (searchString.value) {
        params.title = {
          $iRegexp: searchString.value
        }
      }

      return { query: params }
    })

    const { items: kits, isPending: areKitsPending } = useFind({
      model: Kit,
      fetchParams: kitsitsFetchParams,
      params: kitsParams
    })

    return {
      searchString,
      searchResult,
      kits,
      areKitsPending
    }
  },
  data: () => ({
    parts: []
  }),
  methods: {
    async addParts (kit) {
      const { KitPart } = this.$FeathersVuex.api

      const { data: parts } = await KitPart.find({ query: { kit_id: kit.id, $limit: 99 } })

      if (parts.length) {
        this.parts = parts
        // console.log('parts', parts)
        // console.log('this', this)
        if (this.rows.length < 1) {
          parts.map(part => {
            this.$emit('change', { qty: part.qty, costPrice: part.costPrice, name: part.part, id: part.product_id, listPrice: part.listPrice, description: part.description })
          })
        } else {
          const productIdRows = this.rows.map(row => row.product_id)
          console.log('productIdRows', productIdRows)
          this.rows.map(row => {
            parts.map(part => {
              console.log('part', part.product_id)
              if (row.product_id === part.product_id && productIdRows.includes(part.product_id)) {
                row.qty = row.qty + part.qty
              }
            })
          })
          parts.map(part =>
            !productIdRows.includes(part.product_id)
              ? this.$emit('change', { qty: part.qty, costPrice: part.costPrice, name: part.part, id: part.product_id, listPrice: part.listPrice, description: part.description }) : null
          )
        }

        this.$nextTick(() => {
          this.searchResult = ''
          this.searchString = ''
        })
      }
      return {
        parts
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
