<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="10"
      )
        h1.text-h4.mb-4 {{ verbage }} Invoice
        v-card(
          outlined
        )
          FeathersVuexFormWrapper(
            v-if="item"
            :item="item"
            watch
          )
            template(v-slot="{ clone, save, reset, isDirty }")
              .card
                v-form(
                  @submit.prevent="saveInvoice(save)"
                  ref="form"
                  v-model="valid"
                )
                  v-card-text
                    v-row
                      v-col(
                        cols="12"
                        md="6"
                        lg="4"
                      )
                        v-text-field(
                          type="date"
                          label="Date"
                          filled
                          v-model="clone.date"
                          :rules="[rules.required]"
                        )

                      v-col(
                        cols="12"
                        md="6"
                        lg="4"
                      )
                        v-text-field(
                          ref="roNumber"
                          label="RO"
                          filled
                          v-model="clone.roNumber"
                          hint="Optional"
                          persistent-hint
                          outlined
                        )

                      v-col(
                        cols="12"
                        md="6"
                        lg="4"
                      )
                        TechsAutocomplete(
                          v-model="clone.tech_id"
                          :query="{ shop_id: shopId || item.shop_id }"
                          hint="Optional"
                        )

                  v-data-table.clickable(
                    :headers="headers"
                    no-data-text="No Products Found"
                    disable-pagination
                    :items="rows"
                    @click:row=""
                    hide-default-footer
                    :items-per-page="-1"
                  )
                    template(#item.qty="{ item: row }")
                      v-text-field(
                        outlined
                        min="1"
                        v-model.number='row.qty'
                        :rules='[rules.required]'
                        hide-details="auto"
                        label='Edit'
                        single-line
                        type="number"
                        dense
                        style="width: 60px;"
                      )

                    template(#item.actions="{ item: product }")
                      v-btn(
                        icon
                        @click="removeRow(product)"
                      )
                        v-icon mdi-delete

                    template(#item.listPrice="{ item: product }")
                      v-edit-dialog(
                        large
                        v-model="product.listPrice"
                      ) ${{ Number(product.listPrice).toFixed(2) | round(2) }}
                        template(v-slot:input)
                          v-text-field(
                            v-model="product.listPrice"
                            single-line
                          )
                    template(#item.costPrice="{ item: product }")
                      v-edit-dialog(
                        large
                        v-model="product.costPrice"
                      ) ${{ Number(product.costPrice).toFixed(2) | round(2) }}
                        template(v-slot:input)
                          v-text-field(
                            v-model="product.costPrice"
                            single-line
                          )
                    template(#item.listSubtotal="{ item: product }")
                      span ${{ Number(product.listSubtotal).toFixed(2) | round(2) }}
                    template(#item.costSubtotal="{ item: product }")
                      span ${{ Number(product.costSubtotal).toFixed(2) | round(2) }}

                    template(#body.prepend)
                      tr
                        td.pa-4(colspan="5")
                          ProductsAutocomplete(
                            @change="addRow"
                            :shopId="shopId"
                          )
                        td.pa-4(colspan="5")
                          PartsKitsAutocomplete(
                            :rows="rows"
                            @change="addRow"
                            :shopId="shopId"
                          )
                    template(#body.append)
                      tr
                        td(
                          colspan="4"
                        )
                        td Totals:
                        td ${{ costTotal.toFixed(2) | round(2) }}
                        td ${{ listTotal.toFixed(2) | round(2) }}
                        td

                  v-divider
                  v-card-actions.pa-4
                    v-spacer
                    v-btn(
                      @click="cancel"
                    ) Cancel
                    v-btn(
                      color="primary"
                      type="submit"
                      :disabled="!valid"
                      min-width="150px"
                    ) Save
      v-layout(flex flex-column)
        h4.mx-auto.font-weight-thin.disclaimer **All published pricing is Suggested Cost and Suggested List Pricing for Cost Recovery Purposes**
        h4.mx-auto.font-weight-thin.disclaimer *Pricing is not reflective of actual cost from ASP Clips or its Distributors*
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex'
import validations from '@/mixins/validationRulesMixin'
import ProductsAutocomplete from '@/components/ProductsAutocomplete'
import PartsKitsAutocomplete from '@/components/PartsKitsAutocomplete'
import TechsAutocomplete from '@/components/TechsAutocomplete'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const accumulator = (accumulator, currentValue) => accumulator + currentValue

export default {
  name: 'EditInvoice',
  components: {
    FeathersVuexFormWrapper,
    ProductsAutocomplete,
    PartsKitsAutocomplete,
    TechsAutocomplete
  },
  mixins: [
    validations
  ],
  computed: {
    verbage () {
      return this.invoiceId === 'new' ? 'Create' : 'Edit'
    },
    invoiceId () {
      return this.$route.params.invoiceId
    },
    shopId () {
      return this.$route.query.shopId || this.item.shop_id
    },
    isEdit () {
      return this.invoiceId !== 'new'
    },
    // Returns a new Invoice if the route `id` is 'new', or returns an existing Invoice.
    item () {
      const { Invoice } = this.$FeathersVuex.api

      const item = this.invoiceId === 'new' ? new Invoice({ shop_id: this.shopId }) : Invoice.getFromStore(this.invoiceId)

      if (item) {
        const invoiceDate = dayjs(item.date).format()
        item.date = dayjs.utc(invoiceDate).format('YYYY-MM-DD')
      }

      return item
    },
    listTotal () {
      if (!this.rows.length) {
        return 0
      }
      const listSubtotals = this.rows.map(row => row.listSubtotal)
      const listTotal = listSubtotals.reduce(accumulator)

      return listTotal
    },
    costTotal () {
      if (!this.rows.length) {
        return 0
      }
      const costSubtotals = this.rows.map(row => row.costSubtotal)
      const costTotal = costSubtotals.reduce(accumulator)

      return costTotal
    },
    formattedDate () {
      const invoiceDate = dayjs(this.item.date).format()
      return dayjs.utc(invoiceDate).format('YYYY-MM-DD')
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Qty.',
        value: 'qty'
      },
      {
        text: 'Item',
        value: 'name'
      },
      {
        text: 'Description',
        value: 'description'
      },
      {
        text: 'Cost Price',
        value: 'costPrice'
      },
      {
        text: 'List Price',
        value: 'listPrice'
      },
      {
        text: 'Cost Subtotal',
        value: 'costSubtotal'
      },
      {
        text: 'List Subtotal',
        value: 'listSubtotal'
      },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    rows: [],
    rowsToRemove: []
  }),
  watch: {
    invoiceId: {
      async handler (val) {
        // Early return if the route `:id` is 'new'
        if (val === 'new') {
          return
        }

        const { Invoice } = this.$FeathersVuex.api
        const existingRecord = Invoice.getFromStore(val)

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Invoice.get(val)
        }

        const { InvoiceRow } = this.$FeathersVuex.api

        const { data: rows } = await InvoiceRow.find({ query: { invoice_id: this.invoiceId, $limit: 99 } })

        if (rows.length) {
          this.rows = rows
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true
    }
  },
  mounted () {
    if (!this.isEdit) {
      this.$refs.roNumber.focus()
    }
  },
  methods: {
    addRow (product) {
      console.log('product', product)
      this.isProductDialogOpen = false

      const { InvoiceRow } = this.$FeathersVuex.api

      const newProduct = new InvoiceRow({ qty: product.qty || 1, costPrice: product.costPrice, name: product.name || product.part, product_id: product.id, listPrice: product.listPrice, description: product.description })

      this.rows.push(newProduct)
    },
    removeRow (row) {
      //                                    Use this method to add the instance to the array
      this.rowsToRemove = this.rowsToRemove.concat([row])

      const editedIndex = this.rows.indexOf(row)
      this.rows.splice(editedIndex, 1)
    },
    async saveInvoice (save) {
      try {
        const savedInvoice = await save()

        this.rows.map(row => { row.invoice_id = savedInvoice.id })

        for (let i = 0; i < this.rows.length; i++) {
          await this.rows[i].save()
        }

        for (let i = 0; i < this.rowsToRemove.length; i++) {
          const row = this.rowsToRemove[i]
          await row.remove()
        }

        // Send empty patch to trigger recalculation
        await savedInvoice.patch({ data: {} })

        this.$router.push({ name: 'invoice', params: { invoiceId: savedInvoice.id } })

        const operativeWord = this.isEdit ? 'updated' : 'created'

        this.$snackSuccess('Invoice ' + operativeWord)
      } catch (error) {
        console.error(error)
      }
    },
    cancel () {
      if (this.isEdit) {
        this.$router.push({ name: 'invoice', params: { invoiceId: this.item.id } })
      } else {
        this.$router.push({ name: 'shop', params: { shopId: this.item.shop_id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .disclaimer {
    font-family: 'Hemi';
  }
</style>
